<template>
	<div class="ClassFee">
		<a-button-group style="margin-top:10px;">
			<a-button :class="index == 1 ? 'ant-btn-primary' : ''" @click="routerBtn(1)">收费标准</a-button>
			<a-button :class="index == 2 ? 'ant-btn-primary' : ''" @click="routerBtn(2)">课程优惠方案</a-button>
			<a-button :class="index == 3 ? 'ant-btn-primary' : ''" @click="routerBtn(3)">物品优惠方案</a-button>
			<a-button :class="index == 4 ? 'ant-btn-primary' : ''" @click="routerBtn(4)">优惠券方案</a-button>
			<a-button :class="index == 5 ? 'ant-btn-primary' : ''" @click="routerBtn(5)">课券管理</a-button>
		</a-button-group>
		<div :style="{
        'border-top': '1px solid #E0E1E2',
        'margin-top': '-1px',
        'min-height': clientH
      }">
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'ClassFee',
		data() {
			return {
				index: 1,
				clientH: '500px',
				urlArr: [
					'/ClassFee/FeeList',
					'/ClassFee/ConcessionList',
					'/ClassFee/ProdList',
					'/ClassFee/FinanceList',
					'/ClassFee/CourseVoucherList',
				]
			}
		},
		created() {
			if (this.$route.path == '/ClassFee/ConcessionList') {
				this.index = 2
			} else if (this.$route.path == '/ClassFee/FeeList') {
				this.index = 1
			} else if (this.$route.path == '/ClassFee/ProdList') {
				this.index = 3
			}  else if (this.$route.path == '/ClassFee/FinanceList') {
				this.index = 4
			}else {
				this.index = 5
			}
		},
		mounted() {
			let domHeight = document.documentElement.clientHeight
			this.clientH = domHeight - 227 + 'px'
		},
		methods: {
			routerBtn(i) {
				this.index = i
				let that = this
				that.$router.push({
					//核心语句
					path: that.urlArr[i - 1] //跳
				})
			}
		}
	}
</script>
<style lang="less">
	.ClassFee {
		position: relative;
		background: #fff;
		border-radius: 4px;

		overflow-y: auto;
		user-select: none;
		font-size: 14px;
		color: #666666;
		padding: 15px;
	}
</style>
